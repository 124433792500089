import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { LoginForm } from './LoginForm';
import { ForgotPassword } from './ForgotPassword';
import { SignUpForm } from './SignUpForm'; // Make sure the path is correct
import { VerifyEmail } from './VerifyEmail'; // Placeholder for your VerifyEmail component
import { AwaitingApproval } from './AwaitingApproval'; // Placeholder for your AwaitingApproval component
import { isUserApproved } from '../firebaseAuth'; // Make sure the path is correct
import classes from './Authentification.module.css';

export const Authentification = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [view, setView] = useState(searchParams.get('view') || 'login');
  const navigate = useNavigate();
  
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (!user.emailVerified) {
          // User's email is not verified, prompt for verification
          setView('verifyEmail');
        } else {
          const checkApproval = async () => {
            const isApproved = await isUserApproved(user.uid);
            if (!isApproved) {
              setView('awaitingApproval');
            } else {
              // User is logged in, redirect them to the root page or another page
              navigate('/');
            }
          };

          checkApproval();
        }
      }
      // If no user is logged in, do nothing and allow access to the auth page
    });

    // Cleanup the subscription
    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    // Define a function that updates the view based on the current query parameters
    const updateViewBasedOnQuery = () => {
      const currentView = searchParams.get('view');
      if (currentView && ['login', 'signup', 'forgotPassword', 'verifyEmail', 'awaitingApproval'].includes(currentView)) {
        setView(currentView);
      } else {
        setView('login');
      }
    };

    // Call the function to ensure the view is correct on initial render
    updateViewBasedOnQuery();

    // Add a listener for changes to the URL
    // This is a workaround since useSearchParams does not trigger re-renders
    window.addEventListener('popstate', updateViewBasedOnQuery);

    // Cleanup the listener when the component unmounts
    return () => window.removeEventListener('popstate', updateViewBasedOnQuery);
  }, [searchParams]);

  return (
    <div className={classes.auth}>
      {view === 'login' && (
        <LoginForm
          onForgotPassword={() => setView('forgotPassword')}
          onSignUp={() => setView('signup')} // Add this prop to handle signup view switch
        />
      )}
      {view === 'forgotPassword' && <ForgotPassword onBackToLogin={() => setView('login')} />}
      {view === 'signup' && <SignUpForm onBackToLogin={() => setView('login')} />}
      {view === 'verifyEmail' && <VerifyEmail />}
      {view === 'awaitingApproval' && <AwaitingApproval />}
    </div>
  );
};
