import { Group, Anchor } from '@mantine/core';
import AscendKit from './AscendKit.png';
import classes from './Footer.module.css';

const links = [
  { link: '#', label: '' },
  { link: '#', label: '' },
  { link: '#', label: '' },
  { link: '#', label: '' },
];
  
  export function Footer() {
    const items = links.map((link) => (
        <Anchor<'a'>
          c="black"
          key={link.label}
          href={link.link}
          onClick={(event) => event.preventDefault()}
          size="sm"
        >
          {link.label}
        </Anchor>
      ));

    return (
        <footer className={classes.footer}>
          <Group justify="space-between" h="100%">
                <a className={classes.logo}><img src={AscendKit} alt="AscendKit" className={classes.logo} ></img></a>
                <Group className={classes.links}>{items}</Group>
          </Group>
        </footer>
    );
  }