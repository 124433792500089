import {
    TextInput,
    PasswordInput,
    Paper,
    Title,
    Text,
    Container,
    Button,
    Anchor,
    Popover,
    Progress,
    Box,
} from '@mantine/core';
import { IconX, IconCheck } from '@tabler/icons-react';
import React, { useState } from 'react';
import { registerUser } from '../firebaseAuth'; // Adjust based on your actual registration function
import classes from './SignUpForm.module.css'; // Assuming similar styling to LoginForm

interface SignUpFormProps {
    onBackToLogin: () => void;
}

// Password requirements and strength calculation function from your example
function PasswordRequirement({ meets, label }: { meets: boolean; label: string }) {
    return (
        <Text c={meets ? 'teal' : 'red'} style={{ display: 'flex', alignItems: 'center' }} mt={7} size="sm">
            {meets ? <IconCheck style={{ width: 14, height: 14 }} /> : <IconX style={{ width: 14, height: 14 }} />}{' '}
            <Box ml={10}>{label}</Box>
        </Text>
    );
}

const requirements = [
    { re: /[0-9]/, label: 'Includes number' },
    { re: /[a-zA-Z]/, label: 'Includes letter' },
    { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Includes special symbol' },
];

function getStrength(password: string) {
    let multiplier = password.length > 5 ? 0 : 1;

    requirements.forEach((requirement) => {
        if (!requirement.re.test(password)) {
            multiplier += 1;
        }
    });

    return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}

export function SignUpForm({ onBackToLogin }: SignUpFormProps) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [loading, setLoading] = useState(false);
    const [feedbackMsg, setFeedbackMsg] = useState('');
    const [feedbackColor, setFeedbackColor] = useState('gray');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [popoverOpened, setPopoverOpened] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordMatchError, setPasswordMatchError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    // Email regex validation
    const validateEmail = (email: string) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    // Password match check
    const checkPasswordMatch = (password: string, confirmPassword: string) => {
        return password === confirmPassword && password.length >= 6;
    };

    // Password strength checks
    const strength = getStrength(password);
    const color = strength === 100 ? 'teal' : strength > 50 ? 'yellow' : 'red';
    const checks = requirements.map((requirement, index) => (
        <PasswordRequirement key={index} label={requirement.label} meets={requirement.re.test(password)} />
    ));

    const validatePassword = (password: string) => {
        const meetsLengthRequirement = password.length >= 6;
        const meetsOtherRequirements = requirements.every((req) => req.re.test(password));
        return meetsLengthRequirement && meetsOtherRequirements;
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const isEmailValid = validateEmail(email);
        const isPasswordValid = validatePassword(password);
        const isPasswordMatch = checkPasswordMatch(password, confirmPassword);
        setEmailError(!isEmailValid);
        setPasswordError(!isPasswordValid);
        setPasswordMatchError(!isPasswordMatch);
        if (!isEmailValid || !isPasswordMatch || !isPasswordValid) {
            return; // Stop the submission if there are errors
        }
        setFeedbackMsg("");
        setLoading(true);
        const { success, message } = await registerUser(email, password, firstName, lastName);
        if (success) {
            setFeedbackColor('green');
            // Perform additional actions on success, e.g., navigation
          } else {
            setFeedbackColor('red');
          }
          setFeedbackMsg(message);
          setLoading(false); // Re-enable the button
    };

    return (
        <Container size={420} my={40} style={{ marginTop: '-20px' }}>
            <Title ta="center" className={classes.title}>
                Create your account
            </Title>
            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                {feedbackMsg && (
                    <Text c={feedbackColor} size="sm" mt="sm" ta="center" mb="sm">
                        {feedbackMsg}
                    </Text>
                )}
                <form onSubmit={handleSubmit}>
                    <TextInput
                        label="First Name"
                        placeholder="John"
                        required
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />

                    <TextInput
                        label="Last Name"
                        placeholder="Doe"
                        required
                        mt="md"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />

                    <TextInput
                        label="Email"
                        placeholder="john.doe@example.com"
                        required
                        mt="md"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={emailError ? "Invalid email format" : null}
                    />

                    {/* Password Input with validation error message */}
                    <Popover opened={popoverOpened} position="bottom" width="target">
                        <Popover.Target>
                            <div onFocusCapture={() => setPopoverOpened(true)} onBlurCapture={() => setPopoverOpened(false)}>
                                <PasswordInput
                                    label="Password"
                                    placeholder="Your password"
                                    required
                                    mt="md"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    error={passwordError ? "Password must be at least 6 characters long and include a number, a letter, and a special symbol" : null}
                                    withAsterisk
                                />
                            </div>
                        </Popover.Target>
                        <Popover.Dropdown>
                            <Progress color={color} value={strength} size={5} mb="xs" />
                            <PasswordRequirement label="Includes at least 6 characters" meets={password.length > 5} />
                            {checks}
                        </Popover.Dropdown>
                    </Popover>

                    <PasswordInput
                        label="Confirm password"
                        placeholder="Repeat your password"
                        required
                        mt="md"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        error={passwordMatchError ? "Passwords do not match" : null}
                    />

                    <Button type="submit" fullWidth mt="xl" disabled={loading}>
                        {loading ? 'Creating account...' : 'Create account'}
                    </Button>
                    <Text c="dimmed" size="sm" ta="center" mt="md">
                        Already have an account?{' '}
                        <Anchor component="button" type="button" onClick={onBackToLogin}>
                            Log in
                        </Anchor>
                    </Text>
                </form>
            </Paper>
        </Container>

    );
}
