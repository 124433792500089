import {
    Title,
    Text,
    Card,
    SimpleGrid,
    Container,
    rem,
    useMantineTheme,
    Box
} from '@mantine/core';
import { IconCircleNumber1Filled, IconCircleNumber2Filled, IconCircleNumber3Filled } from '@tabler/icons-react';
import classes from './BetaAccess.module.css';

const mockdata = [
    {
        title: 'Sign Up',
        description:
            'Use the sign-up form on our website to provide your email address and some basic information about your job search preferences.',
        icon: IconCircleNumber1Filled,
    },
    {
        title: 'Verification',
        description:
            'Since this is a closed beta, we manually review each sign-up request to ensure a good fit and a quality testing environment. Watch for a confirmation email from our team.',
        icon: IconCircleNumber2Filled,
    },
    {
        title: 'Access',
        description:
            'After your account is verified, simply log in to access the beta features. Your journey to a streamlined job search starts here!',
        icon: IconCircleNumber3Filled,
    },
];

export function BetaAccess() {
    const theme = useMantineTheme();
    const features = mockdata.map((feature) => (
        <Card key={feature.title} shadow="md" radius="md" className={classes.card} padding="xl">
            <feature.icon
                style={{ width: rem(50), height: rem(50) }}
                stroke={2}
                color={theme.colors.blue[6]}
            />
            <Text fz="lg" fw={500} className={classes.cardTitle} mt="md">
                {feature.title}
            </Text>
            <Text fz="sm" c="#454A4F" mt="sm">
                {feature.description}
            </Text>
        </Card>
    ));

    return (
        <Container size="lg" py="xl" id="beta-access">

            <Title className={classes.title} ta="center" mt="sm">
                Join the Beta !
            </Title>

            <Text c="#454A4F" className={classes.description} ta="center" mt="md">
                We're delighted to offer a select number of users an early look at our job search platform during
                this beta phase. It's a chance for you to get first-hand experience with our features,
                give feedback, and help us refine the platform.
            </Text>

            <SimpleGrid cols={{ base: 1, md: 3 }} spacing="xl" mt={40}>
                {features}
            </SimpleGrid>

            <Box mt="lg">
                <Text className={classes.note} ta="center">
                    The beta access is intentionally restricted to ensure personalized attention and support.
                    We appreciate your feedback! Your insights are invaluable and will directly influence enhancements and new features.
                    <br></br>
                </Text>
            </Box>
        </Container>
    );
}