import { useState, useCallback } from 'react';

export function useDoubleTap(callback = () => {}, delay = 300) {
  const [tap, setTap] = useState(0);

  const onTouchEnd = useCallback(() => {
    const currentTime = new Date().getTime();
    const tapGap = currentTime - tap;

    if (tapGap < delay && tapGap > 0) {
      callback();
    }

    setTap(currentTime);
  }, [callback, delay, tap]);

  return onTouchEnd;
}


