import {
  HoverCard,
  Group,
  Button,
  UnstyledButton,
  Text,
  SimpleGrid,
  ThemeIcon,
  Anchor,
  Divider,
  Center,
  Box,
  Burger,
  Drawer,
  ScrollArea,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconMailOpened,
  IconServer,
  IconHandClick,
  IconFilter,
  IconChevronDown,
} from '@tabler/icons-react';
import AscendKit from './AscendKit.png';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { signOutUser } from '../firebaseAuth';
import classes from './Header.module.css';

const mockdata = [
  {
    icon: IconServer,
    title: 'Aggregation',
    description: 'Compiles listings from multiple job platforms into one streamlined feed',
  },
  {
    icon: IconFilter,
    title: 'Smart Filtering',
    description: 'AI-driven sorting to find jobs that fit your skills and interests',
  },
  {
    icon: IconMailOpened,
    title: 'Tailored Cover Letters',
    description: 'Generates customized cover letters for each job application',
  },
  {
    icon: IconHandClick,
    title: 'Effortless Application',
    description: 'Streamlines your job applications by auto-filling forms with a click',
  },
];

export function Header() {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const theme = useMantineTheme();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authCheckDone, setAuthCheckDone] = useState(false);

  const navigate = useNavigate();
  const navigateToLogin = () => navigate('/authentification');

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, user => {
      setIsLoggedIn(!!user); // `!!user` converts `user` to boolean: true if user exists, false otherwise
      setAuthCheckDone(true); // Set this to true after the auth state is checked
    });

    // Cleanup subscription on component unmount
    return () => unsubscribe();
  }, []);

  const links = mockdata.map((item) => (
    <UnstyledButton className={classes.subLink} key={item.title}>
      <Group wrap="nowrap" align="flex-start">
        <ThemeIcon size={34} variant="default" radius="md">
          <item.icon style={{ width: rem(22), height: rem(22) }} color={theme.colors.blue[6]} />
        </ThemeIcon>
        <div>
          <Text size="sm" fw={500}>
            {item.title}
          </Text>
          <Text size="xs" c="dimmed">
            {item.description}
          </Text>
        </div>
      </Group>
    </UnstyledButton>
  ));

  return (
    <Box pb={120}>
      <header className={classes.header}>
        <Group justify="space-between" h="100%">
          <a href="/" className={classes.logo}><img src={AscendKit} alt="AscendKit" className={classes.logo} ></img></a>

          <Group h="100%" gap={0} visibleFrom="sm">
            <a href="/" className={classes.link}>
              Home
            </a>
            <HoverCard width={600} position="bottom" radius="md" shadow="md">
              <HoverCard.Target>
                <a href="/#features" className={classes.link} >
                  <Center inline>
                    <Box component="span" mr={5}>
                      How It Works
                    </Box>
                    <IconChevronDown
                      style={{ width: rem(16), height: rem(16) }}
                      color={theme.colors.blue[6]}
                    />
                  </Center>
                </a>
              </HoverCard.Target>

              <HoverCard.Dropdown style={{ overflow: 'hidden' }}>
                <Group justify="space-between" px="md">
                  <Text fw={500}>Features</Text>
                  <Anchor href="/#features" fz="xs">
                    View all
                  </Anchor>
                </Group>

                <Divider my="sm" />

                <SimpleGrid cols={2} spacing={0}>
                  {links}
                </SimpleGrid>

                <div className={classes.dropdownFooter}>
                  <Group justify="space-between">
                    <div>
                      <Text fw={500} fz="sm">
                        Join the Beta
                      </Text>
                      <Text size="xs" c="dimmed">
                        Join our beta - Unlock your job search potential !
                      </Text>
                    </div>
                    <a href="/#beta-access"><Button variant="default">Join the Beta</Button></a>
                  </Group>
                </div>
              </HoverCard.Dropdown>
            </HoverCard>


            <a href="/#roadmap" className={classes.link}>
              Roadmap
            </a>
            <a href="/#beta-access" className={classes.link}>
              Beta Access
            </a>
          </Group>

          <Group visibleFrom="sm">
            {authCheckDone && ( // Only render buttons if authCheckDone is true
              isLoggedIn ? (
                <>
                  <Button variant="gradient" onClick={() => navigate('/app')}>Access App</Button>
                  <Button variant="outline" onClick={signOutUser}>Log out</Button>
                </>
              ) : (
                <>
                  <Button variant="default" onClick={() => navigate('/authentification?view=login')}>Log in</Button>
                  <Button onClick={() => navigate('/authentification?view=signup')} >Sign up</Button>
                </>
              )
            )}
          </Group>

          <Burger opened={drawerOpened} onClick={toggleDrawer} hiddenFrom="sm" />
        </Group>
      </header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title="AscendKit"
        hiddenFrom="sm"
        zIndex={1000000}
      >
        <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
          <Divider my="sm" />

          <a href="/" className={classes.link} onClick={closeDrawer}>
            Home
          </a>
          <a href="/#features" className={classes.link} onClick={closeDrawer}>
            How It Works
          </a>
          <a href="/#roadmap" className={classes.link} onClick={closeDrawer}>
            Roadmap
          </a>
          <a href="/#beta-access" className={classes.link} onClick={closeDrawer}>
            Beta Access
          </a>

          <Divider my="sm" />

          <Group justify="center" grow pb="xl" px="md">
          {authCheckDone && ( // Only render buttons if authCheckDone is true
              isLoggedIn ? (
                <>
                  <Button variant="gradient" onClick={() => { closeDrawer(); navigate('/app'); }} >Access App</Button>
                  <Button variant="outline" onClick={() => { closeDrawer(); signOutUser(); navigate('/')}}>Log out</Button>
                </>
              ) : (
                <>
                  <Button variant="default" onClick={() => { closeDrawer(); navigate('/authentification?view=login'); }}>Log in</Button>
                  <Button onClick={() => { closeDrawer(); navigate('/authentification?view=signup'); }}>Sign up</Button>
                </>
              )
            )}
          </Group>
        </ScrollArea>
      </Drawer>
    </Box >
  );
}