import { Image, Container, Text, Button, Group } from '@mantine/core';
import image from './image.svg';
import classes from './HeroTitle.module.css';

export function HeroTitle() {
    return (
        <div className={classes.wrapper} id="home">
            <div className={classes.content}>
                <Container size={700} className={classes.inner}>
                    <h1 className={classes.title}>
                        Elevate Your Job Quest: The{' '}
                        <Text component="span" variant="gradient" gradient={{ from: 'blue', to: 'cyan' }} inherit>
                            All-in-One
                        </Text>{' '}
                        Hub for Job Seeker
                    </h1>

                    <Text className={classes.description}>
                        Begin with intelligent job aggregation and end with successful placements. <b>AscendKit </b>
                        is redefining job search with AI-driven matching, personalized applications, and
                        streamlined tracking — every step toward your new career, <b>simplified</b>.
                    </Text>

                    <Group className={classes.controls}>
                        <a href="#features"><Button
                            size="xl"
                            className={classes.control}
                            variant="gradient"
                            gradient={{ from: 'blue', to: 'cyan' }}
                            // redirect to the Features section (id="features")
                            
                        >
                            Discover
                        </Button>
                        </a>


                    </Group>
                </Container>
            </div>
            <div className={classes.imageContainer}>
                <Image src={image} className={classes.image} />
            </div>
        </div>
    );
}