import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
} from '@mantine/core';
import React, { useState } from 'react';
import { loginUser } from '../firebaseAuth'; // Adjust the path as necessary
import { notifications } from '@mantine/notifications';
import classes from './LoginForm.module.css';

interface LoginFormProps {
  onForgotPassword: () => void;
  onSignUp: () => void;
}

const notify = (message: string, success = true) => {
  notifications.show({
    title: success ? 'Success' : 'Error',
    message: message,
    color: success ? 'green' : 'red',
    autoClose: 5000,

  });
};


export function LoginForm({ onForgotPassword, onSignUp }: LoginFormProps) {
  // State for email and password
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const [loading, setLoading] = useState(false);
  const [feedbackMsg, setFeedbackMsg] = useState('');
  const [feedbackColor, setFeedbackColor] = useState('gray');

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent the default form submit behavior
    setLoading(true); // Disable button and prevent further submissions
    setFeedbackMsg('Signing in...'); // Initial feedback message
    setFeedbackColor('blue'); // Neutral color for processing state
    const { success, message } = await loginUser(email, password, rememberMe);
    if (success) {
      setFeedbackColor('green');
      // Perform additional actions on success, e.g., navigation
    } else {
      setFeedbackColor('red');
    }
    setFeedbackMsg(message);
    setLoading(false); // Re-enable the button
  };


  return (
    <Container size={420} my={40}>
      <Title ta="center" className={classes.title}>
        Welcome back!
      </Title>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        {feedbackMsg && (
          <Text c={feedbackColor} size="sm" mt="sm" ta="center" mb="sm">
            {feedbackMsg}
          </Text>
        )}
        <form onSubmit={handleSubmit}>
          <TextInput
            label="Email"
            placeholder="john.doe@example.com"
            required
            value={email}
            onChange={(event) => setEmail(event.currentTarget.value)}
          />
          <PasswordInput
            label="Password"
            placeholder="Your password"
            required
            mt="md"
            value={password}
            onChange={(event) => setPassword(event.currentTarget.value)}
          />
          <Group justify="space-between" mt="lg">
            <Checkbox
              label="Remember me"
              checked={rememberMe}
              onChange={(event) => setRememberMe(event.currentTarget.checked)}
            />
            <Anchor component="button" type="button" size="sm" onClick={onForgotPassword}>
              Forgot password?
            </Anchor>
          </Group>
          <Button type="submit" fullWidth mt="xl" disabled={loading}>
            {loading ? 'Signing in...' : 'Sign in'}
          </Button>
          <Text c="dimmed" size="sm" ta="center" mt="md">
            Do not have an account yet?{' '}
            <Anchor size="sm" component="button" onClick={onSignUp}>
              Create account
            </Anchor>
          </Text>
        </form>
      </Paper>
    </Container>
  );
}