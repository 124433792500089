import { useState } from 'react';
import { Group, Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconLogout, IconSparkles, IconShoppingBagHeart } from '@tabler/icons-react';
import { ShoppingBagDrawer } from './ShoppingBagDrawer';
import { SearchDrawer } from './SearchDrawer';
import AscendKit from './AscendKit.png';
import classes from './AppHeader.module.css';



export function AppHeader() {
    const [searchDrawerOpened, { open: openSearch, close: closeSearch }] = useDisclosure(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);


    return (

        <header className={classes.header}>
            <SearchDrawer isOpen={searchDrawerOpened} onClose={closeSearch} />

            <Group justify="space-between" h="100%" className={classes.leftGroup}>
                {window.innerWidth > 767 ? (
                    <a href="/" className={classes.logo}><img src={AscendKit} alt="AscendKit" className={classes.logo} ></img></a>
                ) : (
                    <a href="/" className={classes.iconLogo}><IconSparkles size={24} /></a>
                )}

            </Group>

            <Group h="100%" gap={0} className={classes.centerGroup}>
                <Button onClick={openSearch} className={classes.searchButton} variant="gradient" radius="xs">Search Settings</Button>
            </Group>

            <Group h="100%" gap={0} className={classes.rightGroup}>
                <Button onClick={() => setIsDrawerOpen(true)} className={classes.shoppingBagButton} variant="transparent" color="blue" radius="xl"><IconShoppingBagHeart size={24} /></Button>
                <ShoppingBagDrawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />
                <Button className={classes.logoutButton} variant="transparent" color="red" radius="xl"><IconLogout size={24} /></Button>
            </Group>

        </header>
    );
}