import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { MantineProvider, createTheme, MantineColorsTuple } from '@mantine/core';
import '@mantine/core/styles.css';
import './index.css';
import App from './App';

const myColor: MantineColorsTuple = [
  '#f6ecff',
  '#e7d6fb',
  '#caabf1',
  '#ac7ce8',
  '#9354e0',
  '#833cdb',
  '#7b2eda',
  '#6921c2',
  '#5d1cae',
  '#501599'
];


const theme = createTheme({
  colors: {
    myColor,
  },
  /*breakpoints: {
    xs: '576',
    sm: '768',
    md: '992',
    lg: '1200',
    xl: '1400',
  },*/
  components: {
    Select: {
      styles: {
        dropdown: {
          zIndex: 1100,
        },
      },
    },
    MultiSelect: {
      styles: {
        dropdown: {
          zIndex: 1100,
        },
      },
    },
  },
}
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <MantineProvider theme={theme} withCssVariables>
    <React.StrictMode>
        <App />
    </React.StrictMode>
  </MantineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
