import React, { useState, useEffect } from 'react';
import { Card, Text, Group, Badge, Button, Stack, ActionIcon, useMantineTheme, Divider, ScrollArea } from '@mantine/core';
import { IconHeart, IconShoppingBagPlus, IconThumbDown, IconCalendar, IconBuildingSkyscraper, IconMapPin, IconBriefcase, IconAward, IconUserCheck, IconGlobe, IconHeartFilled } from '@tabler/icons-react';
import { useShoppingBag } from '../context/ShoppingBagContext'; // Adjust the import path as needed
import { Key } from 'react';
import { useDoubleTap } from '../hooks/useDoubleTap';
import classes from './JobCard.module.css';


export interface JobCardProps {
  id: Key;
  title: string;
  company: string;
  location: string;
  type: string;
  level: string;
  category: string;
  yearsOfExperience: number;
  citizenship: string;
  datePosted: string;
  description: string;
  jobLink: string;
}

export function JobCard({
  id,
  title,
  company,
  location,
  type,
  level,
  category,
  yearsOfExperience,
  citizenship,
  datePosted,
  description,
  jobLink
}: JobCardProps) {
  const theme = useMantineTheme();

  const { toggleInBag, shoppingBag, applyForJob } = useShoppingBag();


  // State to manage the variant of the icons
  const [heartVariant, setHeartVariant] = useState('default');
  const [bagVariant, setBagVariant] = useState('default');

  // Determine initial state based on shopping bag contents
  useEffect(() => {
    const jobInBag = shoppingBag.find((item: { id: React.Key; }) => item.id === id);
    if (jobInBag) {
      setBagVariant('light');
      setHeartVariant(jobInBag.loved ? 'light' : 'default');
    } else {
      setBagVariant('default');
      setHeartVariant('default');
    }
  }, [id, shoppingBag]);


  const handleToggleBag = (loved: boolean) => {
    const jobDetails = { id, title, company, location, type, level, category, yearsOfExperience, citizenship, datePosted, description, jobLink };
    toggleInBag(jobDetails, loved);

    if (loved) {
      setHeartVariant(heartVariant === 'default' ? 'light' : 'default');
      setBagVariant(bagVariant === 'default' ? 'light' : 'default');
    } else {
      setBagVariant(bagVariant === 'default' ? 'light' : 'default');
      if (heartVariant === 'light') {
        setHeartVariant('default');
      }
    }
  };


  const job = { id, title, company, location, type, level, category, yearsOfExperience, citizenship, datePosted, description, jobLink };

  

  const onDoubleTap = useDoubleTap(() => handleToggleBag(true));

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder className={classes.card} onTouchEnd={onDoubleTap}>
      <Group align="apart">
        <Group>
            <Text className={classes.title}>{title}</Text>
            <Badge color="blue" variant="light">{type.toUpperCase()}</Badge>
        </Group>
        <Group wrap="initial" gap="xs" align="center" className={classes.details}>
            <Group gap="xs" align="center">
                <IconBuildingSkyscraper size={18} />
                <Text size="sm">{company}</Text>
            </Group>
        
            <Group gap="xs" align="center">
                <IconMapPin size={18}/>
                <Text size="sm">{location}</Text>
            </Group>

            <Group gap="xs" align="center">
                <IconCalendar size={18} />
                <Text size="sm">{new Date(datePosted).toLocaleDateString()}</Text>
            </Group>
        </Group>
    </Group>



     <Divider mt="xs" />

     <Stack gap="xs" mt="md">
        <Group gap="xs" align="center">
            <IconBriefcase size={18} color={theme.colors.blue[6]} />
            <Text size="sm" className={classes.details}>Level: <Badge color="blue" variant="light">{level}</Badge></Text>
        </Group>
        
        <Group gap="xs" align="center">
            <IconAward size={18} color={theme.colors.orange[6]} />
            <Text size="sm" className={classes.details}>Category: <Badge color="orange" variant="light">{category}</Badge></Text>
        </Group>
        
        <Group gap="xs" align="center">
            <IconGlobe size={18} color={theme.colors.green[6]} />
            <Text size="sm" className={classes.details}>Years of Experience: <Badge color="green" variant="light">{yearsOfExperience}</Badge></Text>
        </Group>
        
        <Group gap="xs" align="center">
            <IconUserCheck size={18} color={theme.colors.indigo[6]} />
            <Text size="sm" className={classes.details}>Citizenship: <Badge color="indigo" variant="light">{citizenship}</Badge></Text>
        </Group>
    </Stack>

    <Divider mt="md" />
    
    <ScrollArea className={classes.scrollArea} type='auto' mt="md" >
      {description}
    </ScrollArea>

      <Group align="center" mt="md" className={classes.actions}>
        <ActionIcon variant={heartVariant} className={classes.actionIcon} size="lg" bga={theme.colors.red[0]} color={theme.colors.red[6]} onClick={() => handleToggleBag(true)}>
          {heartVariant === 'default' && <IconHeart size={22} color={theme.colors.red[6]} />}
          {heartVariant === 'light' && <IconHeartFilled size={22} color={theme.colors.red[6]} />}

        </ActionIcon>
        <ActionIcon variant={bagVariant} className={classes.actionIcon} size="lg" bga={theme.colors.blue[0]} color={theme.colors.blue[6]} onClick={() => handleToggleBag(false)}>
          <IconShoppingBagPlus size={22} color={theme.colors.blue[6]}/>
        </ActionIcon>
        <ActionIcon variant="default" className={classes.actionIcon} size="lg" bga={theme.colors.gray[0]} color={theme.colors.gray[6]} disabled>
          <IconThumbDown size={22} color={theme.colors.gray[4]} />
        </ActionIcon>
      </Group>

    <a href={jobLink} target="_blank" rel="noreferrer" className={classes.jobLink}>
        <Button variant="filled" color="blue" fullWidth mt="md" radius="md" className={classes.applyButton} onClick={() => applyForJob(job)}>
            Apply Now
        </Button>
    </a>
    </Card>
    );
}
