import { useEffect, useState } from 'react';
import { Avatar, Badge, Table, Group, Text, Loader, ActionIcon, Container, Modal, Button } from '@mantine/core';
import { IconPencil, IconTrash, IconArrowBarUp } from '@tabler/icons-react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { fetchUserData, approveUser  } from '../firebaseAuth';
import { Header } from '../components/Header';
import classes from './AdminDashboard.module.css';


interface User {
    first_name: string;
    last_name: string;
    email: string;
    email_verified: boolean;
    is_approved: boolean;
    last_sign_in_timestamp: string;
    creation_timestamp: string;
    user_type: string;
    user_id: string;
}


export function AdminDashboard() {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([] as User[]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        const loadUsers = async () => {
          const response = await fetchUserData(uid);
          if (response.success) {
            setUsers(response.users);
            setLoading(false);
          } else {
            console.error(response.message);
          }
        };

        loadUsers();
      } else {
        // Handle user not logged in or error
        console.log('No user is logged in or an error occurred');
      }
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [auth]);


  const handleApprove = async () => {
    if (selectedUser) {
      setIsApproving(true); // Start the loader
      const adminUid = auth.currentUser?.uid;
      const targetUid = selectedUser.user_id;
      const response = await approveUser(adminUid, targetUid);
      if (response.success) {
        setUsers(users.map(u => u.user_id === targetUid ? { ...u, is_approved: true } : u));
      } else {
        console.error(response.message);
      }
      setIsApproving(false); // Stop the loader
      setConfirmOpen(false);
      setSelectedUser(null);
    }
  };

  const rows = users.map((user, index) => (
    console.log(user),
    <Table.Tr key={index}>
      <Table.Td>
        <Group gap="sm">
          <Avatar size={30} radius={30} />
          <div>
            <Text size="sm" >{`${user.first_name} ${user.last_name}`}</Text>
            <Text size="xs" c="dimmed">{user.email}</Text>
          </div>
        </Group>
      </Table.Td>
      <Table.Td>
        <Badge color={user.email_verified ? 'green' : 'red'} variant="light">
          {user.email_verified ? 'Verified' : 'Not Verified'}
        </Badge>
      </Table.Td>
      <Table.Td>
        <Badge color={user.is_approved ? 'blue' : 'gray'} variant="light">
          {user.is_approved ? 'Validated' : 'Not Validated'}
        </Badge>
      </Table.Td>
      <Table.Td>{new Date(user.last_sign_in_timestamp).toLocaleDateString()}</Table.Td>
      <Table.Td>{new Date(user.creation_timestamp).toLocaleDateString()}</Table.Td>
      <Table.Td>
        <Badge color={user.user_type === 'admin' ? 'teal' : 'gray'} variant="filled">
          {user.user_type}
        </Badge>
      </Table.Td>
      <Table.Td>
        <Group gap="xs" align="right">
          <ActionIcon color="blue" title="Validate Account" onClick={() => {
            setSelectedUser(user);
            setConfirmOpen(true);
            }}>
            <IconPencil stroke={1.5} />
          </ActionIcon>
          <ActionIcon color="teal" title="Upgrade to Admin">
            <IconArrowBarUp stroke={1.5} />
          </ActionIcon>
          <ActionIcon color="red" title="Delete User">
            <IconTrash stroke={1.5} />
          </ActionIcon>
        </Group>
      </Table.Td>
    </Table.Tr>
  ));

  if (loading) {
    return (
        <div className={classes.loading}>
            <Loader color="blue" size="lg" type="dots" />
            Loading...
        </div>
    );
}

  return (
    <div className={classes.mainApp}>
        <Header />
        <Container className={classes.container}>
            <div className={classes.tableTitle}>
                <Text size="xl" fw={700}>Users</Text>
            </div>
            <div className={classes.tableSubtitle}>
                <Text size="sm" c="dimmed">Manage users and their accounts</Text>
            </div>
            <div className={classes.table} >
                <Table.ScrollContainer minWidth={800}>
                <Table verticalSpacing="sm">
                    <Table.Thead>
                    <Table.Tr>
                        <Table.Th>User</Table.Th>
                        <Table.Th>Email Verified</Table.Th>
                        <Table.Th>Account Validated</Table.Th>
                        <Table.Th>Last Connection</Table.Th>
                        <Table.Th>Account Creation</Table.Th>
                        <Table.Th>Account Type</Table.Th>
                        <Table.Th>Actions</Table.Th>
                    </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>{rows}</Table.Tbody>
                </Table>
                </Table.ScrollContainer>
            </div>
        </Container>
        <Modal
            opened={isConfirmOpen}
            onClose={() => setConfirmOpen(false)}
            title="Confirm User Approval"
            className={classes.approveModal}
            centered
            >
            <Text size="sm">
                {isApproving 
                ? 'Approving the user, please wait...'
                : (
                    <>
                        Are you sure you want to approve 
                        <strong> {selectedUser?.first_name} {selectedUser?.last_name}</strong>?
                    </>
                )}
            </Text>
            {isApproving ? (
                <Loader size="sm" /> // Show loader when approving
            ) : (
                <>
                <Button onClick={handleApprove} disabled={isApproving}>Approve</Button>
                <Button variant="subtle" color="gray" onClick={() => setConfirmOpen(false)}>Cancel</Button>
                </>
            )}
        </Modal>
    </div>
  );
}
