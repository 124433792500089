import { useState } from 'react';
import {
    Paper,
    Title,
    Text,
    TextInput,
    Button,
    Container,
    Group,
    Anchor,
    Center,
    Box,
    rem,
  } from '@mantine/core';
  import { IconArrowLeft } from '@tabler/icons-react';
  import { resetPassword } from '../firebaseAuth';
  import classes from './ForgotPassword.module.css';


  interface ForgotPasswordProps {
    onBackToLogin: () => void;
  }
  
  
  export function ForgotPassword({ onBackToLogin }: ForgotPasswordProps) {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [feedbackMsg, setFeedbackMsg] = useState('');
    const [feedbackColor, setFeedbackColor] = useState('gray');

      // Handle form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setFeedbackMsg('Sending reset link...');
    setFeedbackColor('blue'); // Neutral color for processing state
    const { success, message } = await resetPassword(email);
    if (success) {
      setFeedbackColor('green');
    } else {
      setFeedbackColor('red');
    }
    setFeedbackMsg(message);
    setLoading(false); // Re-enable the button
  };

    return (
      <Container size={460} my={30}>
        <Title className={classes.title} ta="center">
          Forgot your password?
        </Title>
        <Text c="dimmed" fz="sm" ta="center">
          Enter your email to get a reset link
        </Text>
  
        <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
          {feedbackMsg && (
              <Text c={feedbackColor} size="sm" mt="sm" ta="center" mb="sm">
                {feedbackMsg}
              </Text>
            )}
          <form onSubmit={handleSubmit}>
          <TextInput
            label="Your email"
            placeholder="john.doe@example.com"
            required
            value={email}
            onChange={(event) => setEmail(event.currentTarget.value)}
          />
          <Group justify="space-between" mt="lg" className={classes.controls}>
            <Anchor c="dimmed" size="sm" className={classes.control} onClick={onBackToLogin}>
              <Center inline>
                <IconArrowLeft style={{ width: rem(12), height: rem(12) }} stroke={1.5} />
                <Box ml={5}>Back to the login page</Box>
              </Center>
            </Anchor>
            <Button className={classes.control} disabled={loading} type="submit">
              {loading ? 'Sending...' : 'Reset password'}
            </Button>
          </Group>
          </form>
        </Paper>
      </Container>
    );
  }