import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HeroTitle } from './components/HeroTitle';
import { Header } from './components/Header';
import { Features } from './components/Features';
import { Roadmap } from './components/Roadmap';
import { BetaAccess } from './components/BetaAccess';
import { Footer } from './components/Footer';

import { Authentification } from './authentification/Authentification';
import { MainApp } from './MainApp/MainApp';
import { AdminDashboard } from './admin/AdminDashboard';

// src/App.js or your main component file
import { ShoppingBagProvider } from './context/ShoppingBagContext';

import './App.css';

function App() {

  return (
    <ShoppingBagProvider>
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={
            <>
              <Header />
              <HeroTitle />
              <br />
              <br />
              <br />
              <br />
              <Features />
              <Roadmap />
              <br />
              <br />
              <br />
              <br />
              <br />
              <BetaAccess />
              <Footer />
            </>
          } />
          <Route path="/authentification" element={
            <>
              <Header />
              <Authentification />
              <Footer />
            </>
          } />
          <Route path="/app" element={
            <>
              <MainApp />
            </>
          } />
          <Route path="/admin" element={
            <>
              <AdminDashboard />
            </>
          } />
        </Routes>
      </div>
    </Router>
    </ShoppingBagProvider>
  );
}

export default App;
