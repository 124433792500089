import React, { useState, useEffect } from 'react';
import { JobCard, JobCardProps } from './JobCard'; // Assuming JobCard is in the same directory
import { useSwipeable } from 'react-swipeable';
import classes from './JobDeck.module.css'; // Import the CSS module from the JobCard onent

interface JobDeckProps {
    jobs: JobCardProps[]; // Use the same props type as your JobCard
}

export function JobDeck({ jobs }: JobDeckProps) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [animate, setAnimate] = useState(false);
    const [isNavigating, setIsNavigating] = useState(false); // New state to track navigation status
    const [bounce, setBounce] = useState(false); // New state for bounce effect

    // Function to navigate to the next job
    const nextJob = () => {
        if (currentIndex < jobs.length - 1 && !isNavigating) {
            setIsNavigating(true); // Disable further navigation
            setAnimate(true); // Trigger the animation
            setTimeout(() => {
                setCurrentIndex((prevIndex) => prevIndex + 1);
                setAnimate(false); // Reset animation state
                setIsNavigating(false); // Re-enable navigation
            }, 500); // Duration matching the CSS animation
        } else {
            // Trigger bounce effect if at the end
            setBounce(true);
            setTimeout(() => setBounce(false), 500); // Reset bounce state after animation
        }
    };

    const [animateBackwards, setAnimateBackwards] = useState(false); // State to control reverse animation

    const prevJob = () => {
        if (currentIndex > 0 && !isNavigating) {
            setCurrentIndex((prevIndex) => prevIndex - 1);
            setIsNavigating(true); // Disable further navigation
            setAnimateBackwards(true); // Trigger the reverse animation
            setTimeout(() => {
                setAnimateBackwards(false); // Reset animation state
                setIsNavigating(false); // Re-enable navigation
            }, 500); // Duration matching the CSS animation
        } else {
            // Trigger bounce back effect if at the start
            setBounce(true);
            setTimeout(() => setBounce(false), 500); // Reset bounce state after animation
        }
    };

    const swipeHandlers = useSwipeable({
        onSwipedUp: (eventData) => {
            if (Math.abs(eventData.velocity) > 1.1) {
                nextJob();
            }
        },
        onSwipedDown: (eventData) => {
            if (Math.abs(eventData.velocity) > 1.1) {
                prevJob();
            }
        },
        trackMouse: true,
        trackTouch: true,
    });


    // Handle keyboard arrow navigation
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'ArrowDown') {
                nextJob();
            } else if (event.key === 'ArrowUp') {
                prevJob();
            }
        };

        // We also want to do the same when we use the 2 finger pad navigation
        const handlePadNavigation = (event: WheelEvent) => {
            // we want to ensure that the pad is not too sensitive
            if (event.deltaY > 30) {
                nextJob();
            } else if (event.deltaY < -30) {
                prevJob();
            }
        }

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('wheel', handlePadNavigation);

        // Cleanup the event listener
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('wheel', handlePadNavigation);
        };
    }, [currentIndex, jobs.length, isNavigating]); // Dependency array ensures useEffect is run again if the number of jobs changes

    return (
        <div className={`${bounce ? (currentIndex === 0 ? classes.bounceBack : classes.bounce) : ''}`} {...swipeHandlers}>
            {jobs.slice(currentIndex, currentIndex + 3).map((job, index) => (
                <div
                    key={index}
                    className={`${animate
                            ? index === 0
                                ? classes.slideUpWithOpacity
                                : classes.slideUp
                            : animateBackwards
                                ? index === 0
                                    ? classes.slideDownWithOpacity
                                    : classes.slideDown
                                : ''
                        }`}
                >
                    <JobCard {...job} />
                </div>
            ))}
        </div>
    );
}
