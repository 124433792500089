import React from 'react';
import { Drawer, Button, Group, Badge, Divider } from '@mantine/core';
import { IconBriefcase, IconBuildingSkyscraper, IconMapPin, IconExternalLink, IconTrash, IconGlobe, IconArrowBack } from '@tabler/icons-react';
import { JobCardProps } from './JobCard'; // Adjust the import path as needed
import { useShoppingBag } from '../context/ShoppingBagContext'; // Adjust the import path as needed
import classes from './ShoppingBagDrawer.module.css';

interface ShoppingBagDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ShoppingBagDrawer: React.FC<ShoppingBagDrawerProps> = ({ isOpen, onClose }) => {
  const { shoppingBag, removeFromBag, clearBag, appliedJobs, applyForJob, returnToBag } = useShoppingBag();

  return (
    <Drawer
        opened={isOpen}
        onClose={onClose}
        position="right"
        overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
        transitionProps={{ transition: 'slide-left', duration: 100, timingFunction: 'linear' }}
        zIndex={1000}
        title={<div className={classes.drawerTitle}>Your Next Career Move Awaits</div>}
        padding="md"
        size="sm"
        classNames={{ root: classes.drawerContent, content: classes.drawerContentInner, header: classes.drawerHeader }}
    >
      <p className={classes.drawerParagraph}>Welcome to your curated list of opportunities. Ready to ascend?</p>
      {shoppingBag.length > 0 ? (
        <div>
          {shoppingBag.map((job: JobCardProps) => (
            <div key={job.id.toString()} className={classes.jobItem}>
              <div>
                <div className={classes.jobTitle}>{job.title}</div>
                <Group wrap="initial" gap="xs" align="center" >
                    <Badge className={classes.badge} color="dark" variant="light"><IconBuildingSkyscraper size={14} /> {job.company.length > 27 ? job.company.slice(0, 27) + '...' : job.company}</Badge>
                    <Badge className={classes.badge} color="dark" variant="light"><IconMapPin size={14} /> {job.location.length > 27 ? job.location.slice(0, 27) + '...' : job.location}</Badge>
                    <Group gap="xs">
                        <Badge className={classes.badge} color="blue" variant="light"><IconBriefcase size={14} /> {job.level}</Badge>
                        <Badge className={classes.badge} color="green" variant="light"><IconGlobe size={14} /> {job.yearsOfExperience}+ yrs </Badge>
                    </Group>
                </Group>
              </div>
              <Group gap="xs">
                <Button size="xs" color="red" className={classes.removeButton} onClick={() => removeFromBag(job.id.toString())}><IconTrash size={16} /></Button>
                <Button size="xs" component="a"  href={job.jobLink} target="_blank" rel="noreferrer" onClick={() => applyForJob(job)} className={classes.applyButton}><IconExternalLink size={16} /> Apply</Button>
              </Group>
            </div>
          ))}
          <Group className={classes.buttonGroup}>
            <Button color="blue" className={classes.applyAllButton} disabled>Apply to All</Button>
            <Button color="red" className={classes.emptyBagButton} onClick={clearBag}>Clear List</Button>
          </Group>
        </div>
      ) : (
        <div className={classes.emptyBagMessage}>Your list is empty. Explore and swipe up jobs you’re excited about.</div> 
      )}
      {appliedJobs.length > 0 && (
        <div>
        <Divider size="" className='divider' style={{ margin: "40px" }} orientation='horizontal' color='gray' />
        <p className={classes.drawerParagraph}>You applied to the following opportunities</p>

        {appliedJobs.map((job: JobCardProps) => (
          <div key={job.id.toString()} className={classes.appliedJobItem}>
            <div>
            <div className={classes.jobTitle}>{job.title}</div>
                <Group wrap="initial" gap="xs" align="center" >
                    <Badge className={classes.badge} color="dark" variant="light"><IconBuildingSkyscraper size={14} /> {job.company.length > 27 ? job.company.slice(0, 27) + '...' : job.company}</Badge>
                    <Badge className={classes.badge} color="dark" variant="light"><IconMapPin size={14} /> {job.location.length > 27 ? job.location.slice(0, 27) + '...' : job.location}</Badge>
                    <Group gap="xs">
                        <Badge className={classes.badge} color="dark" variant="light"><IconBriefcase size={14} /> {job.level}</Badge>
                        <Badge className={classes.badge} color="dark" variant="light"><IconGlobe size={14} /> {job.yearsOfExperience}+ yrs </Badge>
                    </Group>
                </Group>
            </div>
            <Group gap="xs">
              <Button size="xs" color="gray" className={classes.returnToBagButton} onClick={() => returnToBag(job.id.toString())}><IconArrowBack size={16} />Undo</Button> 
            </Group>
          </div>

        ))}
      </div>
      )}
    </Drawer>
  );
};

