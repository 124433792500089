import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { isUserApproved } from '../firebaseAuth';
import { Loader, Container } from '@mantine/core';
import { AppHeader } from './AppHeader';
import { JobDeck } from './JobDeck';
import classes from './MainApp.module.css';


export const MainApp = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    // A ref to access the container that holds the cards
    const cardsContainerRef = useRef(null);

    // This would be your actual job data pulled from a database or API
    const jobs = [
        // ... array of job objects

        // Example job object
        {
            id: '1',
            title: 'Frontend Developer',
            company: 'Mantine Inc.',
            location: 'Paris, Ile-de-France',
            type: 'Full-time',
            level: 'Mid',
            category: 'Web Developer',
            yearsOfExperience: 4,
            citizenship: 'United States',
            datePosted: '2022-01-01T00:00:00Z',
            description: 'We are looking for a frontend developer with experience in React, TypeScript, and Mantine UI library. The ideal candidate will have at least 3 years of experience in frontend development, and be able to work in a fast-paced environment. You must be eligible to work in the United States, and be able to work at our office in San Francisco, CA. We are looking for a frontend developer with experience in React, TypeScript, and Mantine UI library. The ideal candidate will have at least 3 years of experience in frontend development, and be able to work in a fast-paced environment. You must be eligible to work in the United States, and be able to work at our office in San Francisco, CA. We are looking for a frontend developer with experience in React, TypeScript, and Mantine UI library. The ideal candidate will have at least 3 years of experience in frontend development, and be able to work in a fast-paced environment. You must be eligible to work in the United States, and be able to work at our office in San Francisco, CA.',
            jobLink: 'https://ascendkit.com/'
        },

        // ... more job objects
        {
            id: '2',
            title: 'Backend Developer',
            company: 'OverRice',
            location: 'New York, NY',
            type: 'Part-Time',
            level: 'Junior',
            category: 'Software Engineer',
            yearsOfExperience: 0,
            citizenship: 'United States',
            datePosted: '2023-02-01T00:00:00Z',
            description: 'We are looking for a backend developer with experience in Node.js, Express, and MongoDB. The ideal candidate will have at least 1 year of experience in backend development, and be able to work in a fast-paced environment. You must be eligible to work in the United States, and be able to work at our office in New York, NY.',
            jobLink: 'https://ascendkit.com/'
        },

        // ... more job objects
        {
            id: '3',
            title: 'Cybersecurity Analyst',
            company: 'Apple Inc.',
            location: 'Chicago, IL',
            type: 'Internship',
            level: 'Junior',
            category: 'Cybersecurity',
            yearsOfExperience: 0,
            citizenship: 'United States',
            datePosted: '2022-01-01T00:00:00Z',
            description: 'We are looking for a cybersecurity analyst with experience in network security, penetration testing, and incident response. The ideal candidate will have at least 1 year of experience in cybersecurity, and be able to work in a fast-paced environment. You must be eligible to work in the United States, and be able to work at our office in Chicago, IL.',
            jobLink: 'https://ascendkit.com/'
        },

        {
            id: '4',
            title: 'Full-stack Developer II',
            company: 'Mantine Inc.',
            location: 'San Francisco, CA',
            type: 'Full-time',
            level: 'Mid',
            category: 'Software Engineer',
            yearsOfExperience: 3,
            citizenship: 'United States',
            datePosted: '2022-01-01T00:00:00Z',
            description: 'We are looking for a full-stack developer with experience in React, Node.js, Express, and MongoDB. The ideal candidate will have at least 3 years of experience in full-stack development, and be able to work in a fast-paced environment. You must be eligible to work in the United States, and be able to work at our office in San Francisco, CA.',
            jobLink: 'https://ascendkit.com/'
        },

        {
            id: '5',
            title: 'Full-stack Developer III',
            company: 'AscendKit',
            location: 'New York, NY',
            type: 'Full-time',
            level: 'Senior',
            category: 'Software Engineer',
            yearsOfExperience: 7,
            citizenship: 'None',
            datePosted: '2022-01-11T00:00:00Z',
            description: 'In this role, you will be responsible for developing and maintaining our web applications, APIs, and databases. You will work closely with our product and design teams to implement new features and improve existing ones. The ideal candidate will have at least 5 years of experience in full-stack development, and be proficient in React, Node.js, Express, and MongoDB. The benefits of working at AscendKit include competitive salary, flexible hours, and the opportunity to work on cutting-edge technologies. If you are passionate about building great products and want to join a fast-growing startup, we would love to hear from you!',
            jobLink: 'https://ascendkit.com/'
        },

        {
            id : '6',
            title: 'Python Developer',
            company: 'Uber Eats',
            location: 'San Francisco, CA',
            type: 'Part-time',
            level: 'Junior',
            category: 'Software Engineer',
            yearsOfExperience: 4,
            citizenship: 'None',
            datePosted: '2022-01-01T00:00:00Z',
            description: 'We are looking for a Python developer with experience in Django, Flask, and PostgreSQL. The ideal candidate will have at least 3 years of experience in backend development, and be able to work in a fast-paced environment. You must be eligible to work in the United States, and be able to work at our office in San Francisco, CA. We are looking for a Python developer with experience in Django, Flask, and PostgreSQL. The ideal candidate will have at least 3 years of experience in backend development, and be able to work in a fast-paced environment. You must be eligible to work in the United States, and be able to work at our office in San Francisco, CA. We are looking for a Python developer with experience in Django, Flask, and PostgreSQL. The ideal candidate will have at least 3 years of experience in backend development, and be able to work in a fast-paced environment. You must be eligible to work in the United States, and be able to work at our office in San Francisco, CA.',
            jobLink: 'https://ascendkit.com/'
        },

        {
            id : '7',
            title: 'Cloud Engineer',
            company: 'Mantine Inc.',
            location: 'Paris, Ile-de-France',
            type: 'Full-time',
            level: 'Junior',
            category: 'Other',
            yearsOfExperience: 2,
            citizenship: 'Frence',
            datePosted: '2022-04-01T00:00:00Z',
            description: 'We are looking for a cloud engineer with experience in AWS, Azure, and Google Cloud Platform. The ideal candidate will have at least 2 years of experience in cloud engineering, and be able to work in a fast-paced environment. You must be eligible to work in the France, and be able to work at our office in Paris, Ile-de-France. The benefits of working at Mantine Inc. include competitive salary, flexible hours, and the opportunity to work on cutting-edge technologies. If you are passionate about building great products and want to join a fast-growing startup, we would love to hear from you! We also offer a relocation package for candidates outside of France. We are looking for a cloud engineer with experience in AWS, Azure, and Google Cloud Platform. The ideal candidate will have at least 2 years of experience in cloud engineering, and be able to work in a fast-paced environment. You must be eligible to work in the France, and be able to work at our office in Paris, Ile-de-France.',
            jobLink: 'https://ascendkit.com/'
        }


    ];

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user && user.emailVerified) {
                // User is signed in and email is verified, now check if account is approved
                const isApproved = await isUserApproved(user.uid);
                if (!isApproved) {
                    // Redirect to waiting-for-approval page
                    navigate('/authentification');
                    window.location.reload();
                } else {
                    // User is approved, allow access to app
                    setLoading(false);
                }
            } else {
                // No user signed in, or email not verified, redirect to login/authentification
                navigate('/authentification');
                window.location.reload();
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    if (loading) {
        return (
            <div className={classes.loading}>
                <Loader color="blue" size="lg" type="dots" />
                Loading...
            </div>
        );
    }

    return (
        <div className={classes.mainApp}>
            <AppHeader />
            <Container>
                <div className={classes.cardsContainer} ref={cardsContainerRef}>
                    <JobDeck jobs={jobs} />
                </div>
            </Container>

        </div>
    );
};
