import React, { useState, useEffect  } from 'react';
import { Card, Text, Button, Group, Stepper, Center, Loader, Space, Title } from '@mantine/core';
import { getAuth, sendEmailVerification } from 'firebase/auth';
import classes from './VerifyEmail.module.css';

export const VerifyEmail = () => {
    const [activeStep, setActiveStep] = useState(1); // Using Stepper to indicate progress
    const [loading, setLoading] = useState(false);
    const [countdown, setCountdown] = useState(60);
    const [timerActive, setTimerActive] = useState(false);

    const auth = getAuth();
    const user = auth.currentUser;

    const resendVerificationEmail = () => {
        if (user && !timerActive) {
            setLoading(true);
            sendEmailVerification(user)
                .then(() => {
                    setActiveStep(1); // Move to next step on success
                    setTimerActive(true);
                    let timer = countdown;
                    const intervalId = setInterval(() => {
                        timer -= 1;
                        setCountdown(timer);
                        if (timer === 0) {
                            clearInterval(intervalId);
                            setTimerActive(false);
                            setCountdown(60); // Reset countdown
                        }
                    }, 1000);
                })
                .catch((error) => console.error("Error sending verification email:", error))
                .finally(() => setLoading(false));
        }
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (user) {
                clearInterval(intervalId);
                window.location.reload();
            }
        }, 30000);

        return () => clearInterval(intervalId);
    }, [user]);

    return (
        <Center className={classes.container}>
            <Card shadow="sm" padding="lg" radius="md" withBorder className={classes.card}>
                <Title order={2} className={classes.title}>Email Verification</Title>
                <Stepper active={activeStep} orientation="vertical" className={classes.stepper}>
                    <Stepper.Step label="Account creation completed" description="Your account has been created successfully">
                        <Text>Your AscendKit account has been created.</Text>
                    </Stepper.Step>
                    <Stepper.Step label="Verify your email address" description="Please verify your email address to continue">
                    </Stepper.Step>
                    <Stepper.Step label="Awaiting account approval" description="Your account will be reviewed for Beta access">
                        <Text>Your account is under review for Beta access.</Text>
                    </Stepper.Step>
                </Stepper>

                <Text>Please check your inbox and spam folder for the verification link to continue.</Text>
                        <Space h="md" />
                <Text>If you did not receive it, you can resend another email below.</Text>
                <Group p="center" mt="md">
                    {loading ? (
                        <Loader />
                    ) : (
                        <Button onClick={resendVerificationEmail} disabled={timerActive} className={classes.resendButton}>
                            Resend Email {timerActive && <span className={classes.countdown}>({countdown}s)</span>}
                        </Button>
                    )}
                </Group>
            </Card>
        </Center>
    );
};
