// src/firebaseAuth.js
import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  signOut,
  browserLocalPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import axios from 'axios';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};


const apiClient = axios.create({
  baseURL: 'https://api.ascendkit.com/',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Function to register a new user
export const registerUser = async (email, password, firstName, lastName) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    await sendEmailVerification(userCredential.user);

    const { uid }= userCredential.user;

    try {
      const response = await apiClient.post('/users/register', {
        user_id: uid,
        first_name: firstName,
        last_name: lastName,
      });
    } catch (error) {
      console.error('Error registering user profile:', error.response ? error.response.data : error.message);
      return { success: false, message: 'An internal error occurred. Please try again later.' };
    }

    return { success: true, message: 'Successfully registered! Please verify your email to access the app.' };
  } catch (error) {
    return { success: false, message: 'An unexpected error occurred. Please try again.' };
  }
};

// Function to log in a user
export const loginUser = async (email, password, rememberMe) => {
  try {
    await auth.setPersistence(rememberMe ? browserLocalPersistence : browserSessionPersistence);
    await signInWithEmailAndPassword(auth, email, password);
    window.location.href = '/';
    return { success: true, message: 'Successfully logged in!' };
  } catch (error) {
    return { success: false, message: 'Invalid email or password. Please try again.' };
  }
};

// Function to send password reset email
export const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return { success: true, message: 'If an account with this email exists, a password reset link has been sent !' };
  }
  catch (error) {
    return { success: false, message: 'Unexpected error occurred. Please try again.' };
  }

};

// Function to sign out a user
export const signOutUser = async () => {
  await signOut(auth);
  window.location.href = '/';
  window.location.reload();
};


// Function to check approval status
export const isUserApproved = async (uid) => {
  try {
    const response = await apiClient.get('/users/is-approved/' + uid);
    return response.data.is_approved;
  } catch (error) {
    console.error('Error checking user approval status:', error);
    throw error;
  }
};


// Function to fetch user data
export const fetchUserData = async (uid) => {
  try {
    const response = await apiClient.get('/users/users', {
      params: {
        admin_id: uid
      }
    });
    console.log(response);
    if (response.data) {
      return { success: true, users: response.data };
    }
    return { success: false, message: 'No data returned' };
  } catch (error) {
    console.error('Error fetching user data:', error.response ? error.response.data : error.message);
    return { success: false, message: error.response ? error.response.data.message : 'An error occurred while fetching data' };
  }
};


// Function to approve a user
export const approveUser = async (adminUid, targetUid) => {
  try {
    const response = await apiClient.post('/users/approve/' + targetUid + '?admin_id=' + adminUid);
    return { success: true, message: 'User has been approved successfully.' };
  } catch (error) {
    console.error('Error approving user:', error.response ? error.response.data : error.message);
    return { success: false, message: 'An error occurred while approving the user.' };
  }
};
