// src/context/ShoppingBagContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const ShoppingBagContext = createContext();

export function useShoppingBag() {
  return useContext(ShoppingBagContext);
}

export const ShoppingBagProvider = ({ children }) => {
  const [shoppingBag, setShoppingBag] = useState([]);
  const [appliedJobs, setAppliedJobs] = useState([]);

  // Load shopping bag from localStorage on initial load
  useEffect(() => {
    const bag = localStorage.getItem('shoppingBag');
    if (bag) {
      setShoppingBag(JSON.parse(bag));
    }
    // TODO: Fetch applied jobs from the last 24 hours from your API
    // fetch('https://api.ascendkit.com/applied?last=24hours')
    //   .then(response => response.json())
    //   .then(data => setAppliedJobs(data))
    //   .catch(error => console.error('Error fetching applied jobs:', error));
  }, []);

  // Update localStorage whenever the shopping bag changes
  useEffect(() => {
    localStorage.setItem('shoppingBag', JSON.stringify(shoppingBag));
  }, [shoppingBag]);

  const addToBag = (job) => {
    // Check if job is already in the bag
    if (shoppingBag.some((item) => item.id === job.id)) return;
    setShoppingBag((prev) => [...prev, job]);
  };

  const removeFromBag = (jobId) => {
    setShoppingBag((prev) => prev.filter((job) => job.id !== jobId));
  };


  const toggleInBag = (job, loved = false) => {
    const exists = shoppingBag.some((item) => item.id === job.id);
    if (exists) {
      if (loved) {
        // Update the job with the new loved status
        setShoppingBag(prev => prev.map(item => item.id === job.id ? { ...item, loved: !item.loved } : item));
      } else {
        // Remove the job from the bag if not setting loved status
        setShoppingBag(prev => prev.filter(item => item.id !== job.id));
      }
      // TODO: Remove this job from the backend or local session storage
    } else {
      setShoppingBag((prev) => [...prev, { ...job, loved }]);
      // TODO: Save this job in the backend or local session storage
    }
  };

  

  const applyForJob = (job) => {
    setShoppingBag((prev) => prev.filter((item) => item.id !== job.id));
    // Check if job is already applied
    if (appliedJobs.some((item) => item.id === job.id)) return;
    setAppliedJobs((prev) => [...prev, job]);
    // TODO: Save this application in the backend or local session storage
  };

  const clearBag = () => {
    setShoppingBag([]);
  };

  const returnToBag = (jobId) => {
    const jobToReturn = appliedJobs.find((job) => job.id === jobId);
    if (!jobToReturn) return;
    setAppliedJobs((prev) => prev.filter((job) => job.id !== jobId));
    setShoppingBag((prev) => [...prev, jobToReturn]);
  };

  return (
    <ShoppingBagContext.Provider value={{ shoppingBag, addToBag, removeFromBag, clearBag, appliedJobs, applyForJob, returnToBag, toggleInBag }}>
      {children}
    </ShoppingBagContext.Provider>
  );
};
