import { Timeline, Text, Center, Title, Container } from '@mantine/core';
import { IconServer, IconFilter, IconMailOpened, IconHandClick, IconDashboard, IconSparkles, IconLanguage, IconRobotFace } from '@tabler/icons-react';
import classes from './Roadmap.module.css';

export function Roadmap() {
  return (
    <div className={classes.wrapper} id="roadmap">
      <Container className={classes.inner}>

        <Title mb={20} className={classes.title}>
          The Roadmap
        </Title>
        
        <Text size="sm" className={classes.description}>
          Take a look at what's coming next. We are honest and transparent about our development process.
        </Text>


        <Center >
          <Timeline active={4} bulletSize={24} lineWidth={2} className={classes.timeline}>
            <Timeline.Item bullet={<IconServer size={13} />} title="Job Aggregation">
              <Text c="dimmed" size="sm">Many Sources already taken into consideration. <Text variant="link" component="span" inherit>New ones will be added.</Text> </Text>
              <Text size="xs" mt={4}>Done</Text>
            </Timeline.Item>

            <Timeline.Item bullet={<IconFilter size={13} />} title="Smart Filtering" >
              <Text c="dimmed" size="sm">All AI models are trained for Tech Jobs.<Text variant="link" component="span" inherit> More global models are coming soon.</Text></Text>
              <Text size="xs" mt={4}>Done</Text>
            </Timeline.Item>

            <Timeline.Item title="Cover Letters Generator" bullet={<IconMailOpened size={13} />} lineVariant="dashed" >
              <Text c="dimmed" size="sm">Feature still under first development stage. <Text variant="link" component="span" inherit></Text></Text>
              <Text size="xs" mt={4}>In Progress</Text>
            </Timeline.Item>

            <Timeline.Item title="Auto-Filling Forms" bullet={<IconHandClick size={13} />} lineVariant="dashed" >
              <Text c="dimmed" size="sm"><Text variant="link" component="span" inherit>Working on an Web Extension that will be compatible with </Text> any job form.</Text>
              <Text size="xs" mt={4}>In Progress</Text>
            </Timeline.Item>

            <Timeline.Item title="Access to variety of Markets" bullet={<IconLanguage size={13} />}>
              <Text c="dimmed" size="sm"><Text variant="link" component="span" inherit>Already compatible with jobs psoted in the USA and France. </Text> New countries incoming.</Text>
              <Text size="xs" mt={4}>In Progress</Text>
            </Timeline.Item>

            <Timeline.Item title="Monitoring Dashboard" bullet={<IconDashboard size={13} />}>
              <Text c="dimmed" size="sm">From the dashboard you will be able to monitor absolutely everything. <Text variant="link" component="span" inherit></Text> </Text>
              <Text size="xs" mt={4}>Not Started</Text>
            </Timeline.Item>

            <Timeline.Item title="AI-Powered Job Recommendations" bullet={<IconSparkles size={13} />}>
              <Text c="dimmed" size="sm">Not started yet due to lack of data. <Text variant="link" component="span" inherit>Beta Testers data will help us to build a first model.</Text></Text>
              <Text size="xs" mt={4}>Not Started</Text>
            </Timeline.Item>

            <Timeline.Item title="Bot Companion" bullet={<IconRobotFace size={14} />}>
              <Text c="dimmed" size="sm">Once all the previous features are implemented, your bot companion will do all of them for you! <Text variant="link" component="span" inherit></Text></Text>
              <Text size="xs" mt={4}>Not Started</Text>
            </Timeline.Item>
          </Timeline>
        </Center>
      </Container>
    </div>
  );
}