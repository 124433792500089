import React, { useEffect  } from 'react';
import { Card, Text, Title, Center, Stepper, Space } from '@mantine/core';
import { getAuth } from 'firebase/auth';
import classes from './AwaitingApproval.module.css';

export const AwaitingApproval = () => {
    const auth = getAuth();
    const user = auth.currentUser;

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (user) {
                clearInterval(intervalId);
                window.location.reload();
            }
        }, 60000);

        return () => clearInterval(intervalId);
    }, [user]);


    return (
        <Center className={classes.container}>
            <Card shadow="sm" padding="lg" radius="md" withBorder className={classes.card}>
                <Title order={2} className={classes.title}>Awaiting Account Approval</Title>
                <Stepper active={2} orientation="vertical" className={classes.stepper}>
                    <Stepper.Step label="Account creation completed" description="Your account has been created successfully">
                        <Text>Your AscendKit account has been created.</Text>
                    </Stepper.Step>
                    <Stepper.Step label="Verify your email address" description="Your email address has been verified">
                        <Text>Your email address has been verified. You are now awaiting account approval.</Text>
                    </Stepper.Step>
                    <Stepper.Step label="Awaiting account approval" description="Your account will be reviewed for Beta access">
                        <Text className={classes.text}>Your account is under review. Once approved, you will be notified via email.</Text>
                        <Space h="md" />
                        <Text>It may take up to 24 hours for your account to be approved.</Text>
                    </Stepper.Step>
                </Stepper>
            </Card>
        </Center>
    );
};
