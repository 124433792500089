import React, { useState, useMemo  } from 'react';
import { Drawer, Switch, Button, NumberInput, MultiSelect, SegmentedControl } from '@mantine/core';
import classes from './SearchDrawer.module.css';

interface SearchDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SearchDrawer: React.FC<SearchDrawerProps> = ({ isOpen, onClose }) => {
  const [remote, setRemote] = useState(false);
  const [seen, setSeen] = useState(true);
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [selectedStates, setSelectedStates] = useState<string[]>([]);
  const [selectedCities, setSelectedCities] = useState<string[]>([]);
  const [remoteStatus, setRemoteStatus] = useState('include'); // 'include', 'only', 'exclude'
  const [filters, setFilters] = useState({});

  const handleFilterChange = (value: any, filter: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filter]: value,
    }));
  };

  // Placeholder filter data
  const filterOptions = {
    category: ['Software Engineer', 'Product Manager'],
    level: ['Entry Level', 'Mid Level', 'Senior Level'],
    experience: ['0-2 years', '3-5 years', '6+ years'],
    citizenship: ['US Citizen', 'French Citizen'],
    countries: ['United States', 'France'],
    states: {
      'United States': ['California', 'New York', 'Texas'],
      'France': ['Île-de-France', 'Nouvelle-Aquitaine', 'Occitanie']
    },
    cities: {
      'California': ['San Francisco', 'Los Angeles'],
      'New York': ['New York City', 'Buffalo'],
      'Texas': ['Austin', 'Houston'],
      'Île-de-France': ['Paris', 'Versailles'],
      'Nouvelle-Aquitaine': ['Bordeaux', 'Limoges'],
      'Occitanie': ['Toulouse', 'Montpellier']
    },
  };

  // Derive state and city options based on selected countries and states
  const availableStates = useMemo(() => {
    return selectedCountries.flatMap((country) => filterOptions.states[country as keyof typeof filterOptions.states] || []);
  }, [selectedCountries, filterOptions.states]);

  const availableCities = useMemo(() => {
    return selectedStates.flatMap((state) => filterOptions.cities[state as keyof typeof filterOptions.cities] || []);
  }, [selectedStates, filterOptions.cities]);

  const handleCountryChange = (countries: string[]) => {
    setSelectedCountries(countries);

    const newStates = selectedStates.filter(state => countries.some(country => filterOptions.states[country as keyof typeof filterOptions.states]?.includes(state)));
    setSelectedStates(newStates);
    setSelectedCities(selectedCities.filter(city => newStates.some(state => filterOptions.cities[state as keyof typeof filterOptions.cities]?.includes(city))));
  };

  const handleStateChange = (states: string[]) => {
    setSelectedStates(states);
    setSelectedCities(selectedCities.filter(city => states.some(state => filterOptions.cities[state as keyof typeof filterOptions.cities]?.includes(city))));
 
  };

  const handleCityChange = (cities: string[]) => {
    setSelectedCities(cities);
  };


  const applyFilters = () => {
    // Implement filter application logic
    console.log(filters);
    onClose();
  };


  return (
    <Drawer
      opened={isOpen}
      onClose={onClose}
      title="Search Filters"
      padding="md"
      size="xl"
      position="top"
      zIndex={1000}
      classNames={{ content: classes.drawerContent }}
    >
      <form onSubmit={(e) => e.preventDefault()}>
        
        <div className={classes.formGroup}>
          {/* Category Select */}
          <MultiSelect
            label="Category"
            placeholder="Select category"
            data={filterOptions.category}
            /*searchable*/
            onChange={(value) => handleFilterChange(value, 'category')}
            hidePickedOptions
          />

          {/* Level Select */}
          <MultiSelect
            label="Level"
            placeholder="Select level"
            data={filterOptions.level}
            /*searchable*/
            onChange={(value) => handleFilterChange(value, 'level')}
            hidePickedOptions
          />
    
          {/* Years of Experience Select */}
          <MultiSelect
            label="Years of Experience"
            placeholder="Select range"
            data={filterOptions.experience}
            /*searchable*/
            onChange={(value) => handleFilterChange(value, 'experience')}
            hidePickedOptions
          />

          {/* Citizenship Select */}
          <MultiSelect
            label="Citizenship"
            placeholder="Select citizenship"
            data={filterOptions.citizenship}
            /*searchable*/
            onChange={(value) => handleFilterChange(value, 'citizenship')}
            hidePickedOptions
          />
    
          {/* Country Select */}
          <MultiSelect
            label="Country"
            placeholder="Select country"
            data={filterOptions.countries}
            searchable
            value={selectedCountries}
            onChange={handleCountryChange}
            hidePickedOptions
          />

          {/* State MultiSelect */}
          {selectedCountries.length > 0 && (
            <MultiSelect
              label="State"
              placeholder="Select state"
              data={availableStates}
              searchable
              value={selectedStates}
              onChange={handleStateChange}
              hidePickedOptions
            />
          )}


          {/* City MultiSelect */}
          {selectedStates.length > 0 && (
            <MultiSelect
              label="City"
              placeholder="Select city"
              data={availableCities}
              searchable
              value={selectedCities}
              onChange={handleCityChange}
              hidePickedOptions
            />
          )}


          {/* NumberInput for days */}
          <NumberInput
            label="Days since posted"
            placeholder="1-14"
            min={1}
            max={14}
            defaultValue={1}
            onChange={(value) => handleFilterChange(value, 'days')}
          />

          {/* Remote SegmentControl */}
          <SegmentedControl
            className={classes.remoteControl}
            value={remoteStatus}
            onChange={setRemoteStatus}
            data={[
              { label: 'Include Remote', value: 'include' },
              { label: 'Remote Only', value: 'only' },
              { label: 'Exclude Remote', value: 'exclude' },
            ]}
            fullWidth
            size={window.innerWidth < 400 ? 'xs' : 'sm'}
          />

          {/* Exclude jobs I've seen Switch */}
          <Switch
            className={classes.seenJobsSwitch}
            label="Exclude jobs I've seen"
            checked={seen}
            onChange={(event) => setSeen(event.currentTarget.checked)}
          />


          <Button onClick={applyFilters} className={classes.applyButton}>Apply Filters</Button>

        </div>
      </form>
    </Drawer>
  );
};

export default SearchDrawer;


/* In the future to add double tap and trigger action for touch screen we should do the followinf:

import { useDoubleTap } from '@mantine/hooks';





*/