import { ThemeIcon, Text, Title, Container, SimpleGrid, rem } from '@mantine/core';
import { IconServer, IconFilter, IconMailOpened, IconHandClick, IconDashboard, IconSparkles } from '@tabler/icons-react';
import classes from './Features.module.css';

export const MOCKDATA = [
    {
        icon: IconServer,
        title: 'Job Aggregation',
        description:
            'Our platform gathers job listings from multiple sources, removing duplicates to present a streamlined feed, enhancing your search efficiency.',
    },
    {
        icon: IconFilter,
        title: 'Smart Filtering',
        description:
            'Refine your job hunt with AI that precisely filters opportunities by level, experience, citizenship, and more, custom-fitting them to your unique career profile.',
    },
    {
        icon: IconMailOpened,
        title: 'Cover Letters Generator',
        description:
            'Generate bespoke cover letters with advanced LLMs that harmonize with the job specifications and your unique professional profile.',
    },
    {
        icon: IconHandClick,
        title: 'Auto-Filling Forms',
        description:
            'Save time on repetitive tasks with our auto-filling feature, which populates application forms with your information, so you can focus on what matters most.',
    },
    {
        icon: IconDashboard,
        title: 'Monitoring Dashboard',
        description:
            'Monitor your job applications and follow-ups with our dashboard, offering insights into statuses and interviews in one centralized place.',
    },
    {
        icon: IconSparkles,
        title: 'AI-Powered Job Recommendations',
        description:
            'Our future update will introduce an AI that learns from your applications to recommend jobs tailored just for you, enhancing your search with personalized suggestions.',
    },
];

interface FeatureProps {
    icon: React.FC<any>;
    title: React.ReactNode;
    description: React.ReactNode;
}

export function Feature({ icon: Icon, title, description }: FeatureProps) {
    return (
        <div>
            <ThemeIcon variant="light" size={40} radius={40}>
                <Icon style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
            </ThemeIcon>
            <Text mt="sm" mb={7}>
                {title}
            </Text>
            <Text size="sm" c="dimmed" lh={1.6}>
                {description}
            </Text>
        </div>
    );
}

export function Features() {
    const features = MOCKDATA.map((feature, index) => <Feature {...feature} key={index} />);

    return (
        <Container className={classes.wrapper} id="features">

            <Title className={classes.title}>Towards an effortless Job Application Journey </Title>

            <Container size={700} p={0}>
                <Text size="sm" className={classes.description} c="black">
                    Our vision is to ease the job application process through a series of innovative tools,
                    progressing towards an ultimate bot that handles everything — your role is simply to oversee
                    the outcomes. Here are the pivotal features under development to make your job hunt a breez
                </Text>
            </Container>

            <SimpleGrid
                mt={40}
                cols={{ base: 1, sm: 2, md: 3 }}
                spacing={{ base: 'xl', md: 50 }}
                verticalSpacing={{ base: 'xl', md: 50 }}
            >
                {features}
            </SimpleGrid>
        </Container>
    );
}